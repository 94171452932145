import { useCallback, useMemo } from 'react'
import { useQuery } from 'apollo-client'
import { track } from 'analytics'
import { useDevice } from 'device'

import bannersStrapiQuery, { type NavBannerDesktopFragmentPayload } from './graph/navigationBanners.strapi.graphql'


type UseBrandsBannerProps = {
  placement: 'perfumes' | 'colognes' | 'discover' | 'search' | 'gifts' | 'shop' | 'profile-nav' | 'burger-nav'
  position?: number
  skip?: boolean
  onClick?: () => void
}

const strapiPlacementMap = {
  'burger-nav': 'mobileBurger',
  'profile-nav': 'mobileProfile',
}

const useStrapiNavigationBanner = ({ placement, position = 0, onClick, skip }: UseBrandsBannerProps) => {
  const { isMobile } = useDevice()

  const { data, isFetching } = useQuery(bannersStrapiQuery, {
    client: 'strapi',
    fetchPolicy: 'cache-first',
    skip,
    context: {
      ssrCache: true,
    },
    variables: {
      isMobile,
    },
  })

  const bannerData = useMemo(() => {
    const placementKey = strapiPlacementMap[placement] || placement
    const placementData = data?.navigationBanner?.data?.attributes?.[placementKey]
    const isMultipleBanners = Array.isArray(placementData)
    const placementDataByPosition = isMultipleBanners
      ? placementData?.[position] as NavBannerDesktopFragmentPayload
      : placementData as NavBannerDesktopFragmentPayload
    const bannerData = placementDataByPosition?.banner?.data?.attributes?.banner?.[0]

    if (!bannerData || !placementDataByPosition.enabled) {
      return null
    }

    // support only 2 versions
    if (bannerData.__typename !== 'ComponentBannerNavigation' && bannerData.__typename !== 'ComponentBannerNavigationV2') {
      return null
    }

    const isV2 = bannerData.__typename === 'ComponentBannerNavigationV2'

    // we don't support legacy version on mobile
    if (isMobile && !isV2) {
      return
    }

    const trackParams = {
      ...bannerData.mixpanel?.trackParams,
      placement: isMobile ? 'Mobile navigation menu' : 'Desktop navigation menu',
    }

    if (isV2) {
      return {
        to: bannerData.actionLink,
        width: bannerData.width,
        height: bannerData.height,
        bgImage: bannerData.image.data.attributes.url,
        trackParams,
        isExternalLink: bannerData.isExternalLink,
      }
    }

    return {
      to: bannerData.actionLink,
      width: bannerData.desktopImage?.desktop?.data?.attributes.width,
      height: bannerData.desktopImage?.desktop?.data?.attributes.height,
      bgImage: bannerData.desktopImage?.desktop?.data?.attributes.url,
      trackParams,
      isExternalLink: bannerData.isExternalLink,
    }
  }, [ placement, position, data, isMobile ])

  const handleClick = useCallback(() => {
    track('Banner click', {
      link: bannerData.to,
      action: 'link',
      ...bannerData.trackParams,
    })

    if (typeof onClick === 'function') {
      onClick()
    }
  }, [ bannerData, onClick ])

  return {
    isFetching,
    bannerData,
    handleClick,
  }
}

export default useStrapiNavigationBanner
